<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="require('@/assets/images/oo_logo.png')" alt="logo" contain class="me-3"></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Parolanızı mı unuttunuz? 🔒</p>
                  <p class="mb-2">E-postanızı girin, size şifrenizi sıfırlamak için talimatlar gönderelim</p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      outlined
                      label="E-Posta"
                      hide-details="auto"
                      class="mb-4"
                    ></v-text-field>

                    <v-btn block type="submit" color="primary" :loading="loading"> Sıfırlama Bağlantısı Gönder </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm">
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Girişe geri dön</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :color="snackbarColor" elevation="12" right :timeout="5000">
      <div class="text-body-2 font-weight-bold text-center">
        {{ snackbarTXT }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const loginForm = ref(null)
    const email = ref('')
    const errorMessages = ref([])
    const loading = ref(false)
    const snackbar = ref(false)
    const snackbarColor = ref(null)
    const snackbarTXT = ref('')
    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      loading.value = true
      const params = new URLSearchParams()
      params.append('method', 'passwordRemember')
      params.append('authMethod', 'EMAIL')
      params.append('q', email.value)
      axios
        .post('', params)
        .then(response => {
          snackbarTXT.value = response.data.msg
          // eslint-disable-next-line eqeqeq
          if (response.data.error == 1) {
            snackbarColor.value = 'error'
            snackbarTXT.value = response.data.msg
          } else {
            snackbarColor.value = 'success'
            snackbarTXT.value = response.data.msg
          }
        })
        .catch(e => {
          snackbarColor.value = 'error'
          // eslint-disable-next-line no-undef
          snackbarTXT.value = response.data.msg
          console.log(e)
        })
        .finally(() => {
          loading.value = false
          snackbar.value = true
        })
    }

    return {
      handleFormSubmit,
      loginForm,
      email,
      errorMessages,
      loading,
      snackbar,
      snackbarTXT,
      snackbarColor,
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
